import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import {
  CONTENT_ITEM_TYPES, CourseListBanner, D3PieChart, DIALOG_NAMES, FeedbackIcon, NoAssignmentsMessage,
  ReportIdentityService, SatCoreRegister, StudentAssignmentsPopup, VIEW_SELECTION, useParams
} from 'sat-core';
// ProgressBar  ImageService  ONLINE_CONTENT_TYPES

import { Container, Popup, Table } from 'semantic-ui-react';
import WeeklyPerformanceChart from '../components/WeeklyPerformanceChart';

import '../css/ProgressViewOverride.less';
import emptyCardImage from '../img/empty-card.png';
import iconCommentActive from '../img/icon-comment-active.svg';
// import lessonDefault from  '../img/default-lesson.svg';

// const samplePieData = [
//   {label: '113', value: 113, color: '#009824', text: 'Advanced'},
//   {label: '53', value: 53, color: '#1b8af4', text: 'Competent'},
//   {label: '24', value: 24, color: '#d69f00', text: 'Beginner'},
// ];
//
// samplePieData.forEach((x) => {
//   x.tooltip = `
//     <div>${x.text}</div>
//     <div>${x.label}</div>
//   `;
// });
//
// const sampleLegendData = {
//   advanced: 113,
//   competent: 53,
//   beginner: 24,
// };

// const sampleBarData = [
//   {averageScore: 10, submittedCount: '11', end: 'Sun, Jan 10', start: 'Mon, Jan 3'},
//   {averageScore: 100, submittedCount: '8', end: 'Sun Dec 27', start: 'Mon, Dec 21'},
//   {averageScore: 45, submittedCount: '22', end: 'Sun, Jan 10', start: 'Mon, Jan 3'},
//   {averageScore: 55, submittedCount: '12', end: 'Sun Dec 27', start: 'Mon, Dec 21'},
//   {averageScore: 50, submittedCount: '1', end: 'Sun, Jan 10', start: 'Mon, Jan 3'},
//   {averageScore: 90.111, submittedCount: '8', end: 'Sun, Jan 10', start: 'Mon, Jan 3'},
//   {averageScore: 70.3, submittedCount: '55', end: 'Sun, Jan 10', start: 'Mon, Jan 3'},
// ];

const Legend = ({ data }) => (
  <div className='legend-container'>
    <div className='legend-item'>
      <div className='performance-badge advanced' />
      <div className='value advanced'>{data.advanced}</div>
      <div>(&gt; 75%)</div>
    </div>
    <div className='legend-item'>
      <div className='performance-badge competent' />
      <div className='value competent'>{data.competent}</div>
      <div>(50-75%)</div>
    </div>
    <div className='legend-item'>
      <div className='performance-badge beginner' />
      <div className='value beginner'>{data.beginner}</div>
      <div>(&lt; 50%)</div>
    </div>
  </div>
);

const PerformanceBadge = ({ value }) => {
  let performanceClass;
  if (value > 75) {
    performanceClass = 'advanced';
  } else if (value >= 50) {
    performanceClass = 'competent';
  } else {
    performanceClass = 'beginner';
  }

  return (
    <div className={`performance-badge ${performanceClass}`} />
  );
};

const ProgressViewOverride = observer((props) => {
  const { history, t } = props;
  const { courseManager, classroomManager, userManager, studentProgressManager, dialogManager, navigationManager } = useContext(MobXProviderContext);
  const { classroomProgressData, coursesVisibility, selectedContentType } = studentProgressManager;
  const { params: { view = VIEW_SELECTION.PROGRESS } } = useParams(props);

  const [renderAssignmentsPopup, setRenderAssignmentsPopup] = useState({ key: '', courseElement: {}, activityInstanceId: '', render: false });

  studentProgressManager.setClassroom(classroomManager.currentClassroomId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => navigationManager.setView(view), []);

  useEffect(() => {
    async function fetchData() {
      const entityId = userManager.userId;
      const classroomId = classroomManager.currentClassroomId;
      await studentProgressManager.fetchStudentProgress(entityId, classroomId);
    }
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFeedBack = async (activityInstanceId) => {
    dialogManager.setOpenDialog(DIALOG_NAMES.STUDENT_FEEDBACK, {
      assignmentInstanceId: activityInstanceId
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.STUDENT_FEEDBACK));
  };

  const showAssignment = async (element, activityInstanceId) => {
    setRenderAssignmentsPopup({ key: Math.random(), courseElement: element, activityInstanceId, render: true });
  };

  const hideAssignment = () => {
    setRenderAssignmentsPopup({ key: '', courseElement: {}, activityInstanceId: '', render: false });
  };

  const CourseElement = ({ element }) => {
    const contentString = ReportIdentityService.constructContentString('', element.elementName, element.elementDescription);
    const gradeData = classroomProgressData.grades[element.elementId];
    const feedbackData = classroomProgressData.feedback[element.elementId];
    let imageUrl = emptyCardImage;
    let gradeNum;
    let gradeStr = '';
    let elementClass;
    let clickable = false;

    switch (element.elementType) {
      case 'UNIT':
        elementClass = 'no-score-element';
        break;
      case 'SECTION':
        elementClass = 'no-score-element';
        break;
      default:
        gradeNum = (typeof gradeData.grade === 'number') && gradeData.grade;
        gradeStr = (typeof gradeData.grade === 'number') ? `${gradeData.grade}%` : 'n/a';
        clickable = true;
        break;
    }

    if (element.attachmentImageUrl) {
      imageUrl = CONTENT_ITEM_TYPES.getContentImageUrl(element.attachmentImageUrl);
    } else if (element.elementImageUrl) {
      imageUrl = CONTENT_ITEM_TYPES.getContentImageUrl(element.elementImageUrl);
    }

    const showAssignmentsClick = (clickable) ?
      () => showAssignment(element, gradeData.activityInstanceId) : null;

    return (
      <Table.Row className={elementClass}>
        <Table.Cell onClick={showAssignmentsClick}>
          <div className='assignment-content'>
            <i aria-hidden='true' className='no-icon' />
            <img alt='' className='content-type-img' src={imageUrl} />
            <Popup
              content={<div className={`assignment-content-title ${clickable ? 'clickable' : ''}`}>{contentString}</div>}
              trigger={<div className={`assignment-content-title ${clickable ? 'clickable' : ''}`}>{contentString}</div>} />
          </div>
        </Table.Cell>
        <Table.Cell textAlign='center'>{gradeStr}</Table.Cell>
        <Table.Cell textAlign='center'>
          {typeof gradeNum === 'number' &&
            <PerformanceBadge value={gradeNum} />}
        </Table.Cell>
        <Table.Cell textAlign='center'>
          {feedbackData && (
            <FeedbackIcon
              className='assignment-feedback'
              clickHandler={() => handleFeedBack(feedbackData.entityId)}
              hasFeedback={true}
              icon={iconCommentActive} />
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  const CourseElements = ({ elements }) => (
    elements.map((element) => (
      <CourseElement key={element.elementId} element={element} />
    ))
  );

  const NoAssignments = () => (
    <NoAssignmentsMessage message='No scores yet' />
  );

  const Assignments = () => (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Content</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Score</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Performance</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Feedback</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {hasAssignments && classroomProgressData.courses.map((course, i) => {
            const iconClassName = (coursesVisibility[course.courseId]) ? 'dropdown icon' : 'dropdown icon counterclockwise rotated';
            const imageUrl = (course.courseImageUrl) ?
              CONTENT_ITEM_TYPES.getContentImageUrl(course.courseImageUrl) : emptyCardImage;

            return (
              <React.Fragment key={course.courseId}>
                <Table.Row className='no-score-element'>
                  <Table.Cell>
                    <div
                      className='assignment-content'
                      onClick={() => studentProgressManager.toggleCourseVisibility(course.courseId)}>
                      <i aria-hidden='true' className={iconClassName} />
                      <img alt='' className='content-type-img' src={imageUrl} />
                      <Popup
                        content={<div className='assignment-content-title'>{course.courseName}</div>}
                        trigger={<div className='assignment-content-title'>{course.courseName}</div>} />
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign='center' />
                  <Table.Cell textAlign='center' />
                  <Table.Cell textAlign='center' />
                </Table.Row>
                {studentProgressManager.coursesVisibility[course.courseId] &&
                <CourseElements elements={course.courseElements} />}
              </React.Fragment>
            );
          })}
        </Table.Body>
      </Table>
      {!hasAssignments && <NoAssignments />}
    </>
  );

  const { currentClassroomId } = classroomManager;
  const course = courseManager.getCurrentCourse(currentClassroomId);
  const gradeCount = Object.keys(classroomProgressData.grades).length;
  const hasAssignments = gradeCount > 0;
  const { summary } = classroomProgressData;
  const summaryPieData = [];
  if (summary) {
    if (summary.advanced) {
      summaryPieData.push({
        label: summary.advanced.toString(),
        value: summary.advanced,
        color: '#009824',
        text: 'Advanced'
      });
    }

    if (summary.competent) {
      summaryPieData.push({
        label: summary.competent.toString(),
        value: summary.competent,
        color: '#1b8af4',
        text: 'Competent'
      });
    }

    if (summary.beginner) {
      summaryPieData.push({
        label: summary.beginner.toString(),
        value: summary.beginner,
        color: '#d69f00',
        text: 'Beginner'
      });
    }

    summaryPieData.forEach((x) => {
      x.tooltip = `
        <div>${x.text}</div>
        <div>${x.label}</div>
      `;
    });
  }

  return (
    <Container className='progress-view progress-view-override' fluid>
      <CourseListBanner
        currentContentType={selectedContentType}
        hideAddStudentButton={true}
        history={history}
        isMyProgress={false}
        title={t('BannerTitle', 'No Progress View Banner Title')} />
      <div className='widget-wrapper'>
        {(userManager.loaded && classroomManager.loaded && course !== null) ? (
          <>
            <div className='left-col'>
              <div className='widget summary-widget'>
                <div className='w-title'>{t('SummaryLabel', 'Missing summary label translation')}</div>
                {hasAssignments && summary ? (
                  <div className='summary'>
                    <D3PieChart
                      data={summaryPieData}
                      fontSize={10}
                      height={116}
                      labelPlacement={0.3}
                      width={116} />
                    <Legend
                      data={summary} />
                  </div>
                )
                  :
                  <div className='no-scores'><p>No scores yet</p></div>}
              </div>
              <div className='widget weekly-performance-widget'>
                <div className='w-title'>{t('WeeklyPerformanceLabel', 'Missing weekly performance label translation')}</div>
                {(hasAssignments && classroomProgressData.weekGrades) ? (
                  <div className='weekly-performance-container'>
                    <WeeklyPerformanceChart data={classroomProgressData.weekGrades} width={320} />
                  </div>
                )
                  :
                  <div className='no-scores'><p>No scores yet</p></div>}
              </div>
            </div>
            <div className='right-col'>
              <div className='widget assignments-widget'>
                <div className='assignments-top-padding' />
                <div className='assignments-widget-inner'>
                  <Assignments />
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      {renderAssignmentsPopup.render && (
        <StudentAssignmentsPopup
          key={renderAssignmentsPopup.key}
          activityInstanceId={renderAssignmentsPopup.activityInstanceId}
          courseElement={renderAssignmentsPopup.courseElement}
          hideSelf={hideAssignment}
          history={history} />
      )}
    </Container>
  );
});

export default ProgressViewOverride;

SatCoreRegister('ProgressView', ProgressViewOverride);
