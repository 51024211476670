/* eslint-disable max-len */
const translations = {
  AccommodationsTable: {
    assessmentSection: 'Designated Supports',
    contentLanguageToggleInfoIconLabel: 'Coming Fall 2024',
    lessonSection: 'UDL Supports'
  },
  AddAssignmentModal: {
    cutScoreInfoText: 'Students are categorized by their average score on this standard.',
    cutScoreLabel: 'Student Average on',
    reportsAndGrading: 'Reports & Scores',
    scoresReleased: 'Release scores to students',
    standardsApproachingLabel: 'Approaches',
    standardsDevelopingLabel: 'Does Not Meet',
    standardsMasteringLabel: 'Masters',
    standardsMeetingLabel: 'Meets',
  },
  AddCourseToClassModal: {
    courseZeroStateMsg: 'You currently have no subscriptions for Sirius Online products. To purchase or renew your subscription to Sirius Online please contact your principal, curriculum administrator, or district administrator.',
    noNewSelectedCourses: 'No new selected content yet.',
    selectCourses: 'Select Content',
    selectedCourses: 'Selected Content'
  },
  AdminClassroomsView: {
    allCourses: 'All Content',
    courseDropdownLabel: 'Content:'
  },
  AdminContentView: {
    assessmentBankLabel: 'Assessment Folder',
    editUnavailableMsg: 'Editing this is not allowed because the assessment is used in a live assessment folder.  Create a copy of this assessment to make changes.',
    editUnavailableTitle: 'Edit Unavailable',
    removeFromLiveMessageSuffix: 'from Live. Teachers will no longer be able to access this Assessment Folder; any active assignments will lost and scores against these assessments will be lost.  Would you like to complete this action?',
    resourceBankLabel: 'Pre-Built Assessments',
    resourceBanksButtonLabel: 'Pre-Built Assessments',
    resourceBanksDescription: 'You can use Pre-Built Assessments as is or customize them to create your own tests.',
    resourceBanksResourcesOnlyDescription: 'Pre-Built Assessments are built by your publisher and their contents may be used to create your own courses.',
  },
  AdminProductsView: {
    productsLabel: 'Content'
  },
  AdminSchoolCard: {
    productsLabel: 'Content'
  },
  AdminTestBuilderView: {
    assessmentBankLabel: 'Assessment Folder',
    editOptionLabelCollection: 'Edit Folder',
    editUnavailableMsg: 'Editing this is not allowed because the assessment is used in a live assessment folder.  Create a copy of this assessment to make changes.',
    editUnavailableTitle: 'Edit Unavailable',
    liveDescription: 'Live Assessment Folders are available to teachers.',
    liveResourcesOnlyDescription: 'Live Assessment Folders are available to teachers.',
    moveToLiveMessageSuffix: 'to Live. The move process will take some time so allow a few minutes for your work to appear in Live. Once live, teachers will have access and you will no longer be able to edit the assessments it contains. However, you will be able to add additional assessments to it later. Would you like to complete this action?',
    myDraftsDescription: 'In My Drafts you can build your own Assessments and Assessment Folders. Move them to Shared Drafts to share with other collaborators. Move your Assessment Folders to Live to share with teachers for use in their classes.',
    myDraftsResourcesOnlyDescription: 'In My Drafts build your Assessment Folders and use options to share with other collaborators or share with your teachers by making your drafts live.',
    removeFromLiveMessageSuffix: 'from Live. Teachers will no longer be able to access this Assessment Folder; any assignments will be lost and scores against these assessments will be lost. Would you like to complete this action?',
    resourceBankLabel: 'Pre-Built Assessments',
    resourceBanksButtonLabel: 'Pre-Built Assessments',
    resourceBanksDescription: 'You can use Pre-Built Assessments as is or customize them to create your own tests.',
    resourceBanksResourcesOnlyDescription: 'Pre-Built Assessments are built by your publisher and their contents may be used to create your own courses.',
  },
  AdminTopNav: {
    productsLabel: 'Content'
  },
  AggregateAssignmentCard: {
    Graded: 'Scored',
    ViewPreview: 'Open',
    gradebookLabel: 'Score'
  },
  AssignmentCard: {
    Graded: 'Scored',
    PopupTextExpired: 'Your assignment has expired. If your students need more time to access and complete it, please add additional days and time to the end date by clicking the assignment edit button.',
    PopupTextExpiring: 'Your assignment is about to expire. If your students need more time to access and complete it, please add additional days and time to the end date by clicking the assignment edit button.',
    ViewPreview: 'Open',
    gradebookLabel: 'Score'
  },
  AssignmentFilter: {
    closed: 'Closed',
    completed: 'Scored',
    graded: 'Scored',
    locked: 'Assigned/Pending',
    ready: 'Assigned/Active',
    started: 'In Progress'
  },
  AssignmentManager: {
    EXPORT_SIR_ACT_ITEM_ANALYSIS: 'Item Analysis Export'
  },
  AssignmentMenu: {
    AssignmentLabel: 'Assignments',
    GradedAssignments: 'Scored'
  },
  AssignmentReportSettings: {
    graded: 'Scored',
    includeInReportsDescription: 'Assignment scores will appear in reports',
    includeInReportsLabel: 'Release scores to reports',
    markGradingComplete: 'Mark Scoring Complete',
    scoresReleasedDescription: 'Assignment scores will be viewable by student',
    scoresReleasedLabel: 'Release scores to students'
  },
  AssignmentStatus: {
    closed: 'Closed',
    completed: 'Scored',
    locked: 'Assigned/Pending',
    ready: 'Assigned/Active',
    started: 'In Progress',
  },
  AssignmentView: {
    ClosedAssignments: 'Closed',
    GradedAssignments: 'Scored'
  },
  AssignmentsPopup: {
    gradebookLabel: 'Scorecard'
  },
  Button: {
    addCourseButton: '+ Add Content'
  },
  ClassCard: {
    course: 'Content',
    courseLabel: 'Content',
    coursesLabel: 'Content',
    readyToGrade: 'Ready to Score:'
  },
  ClassCourses: {
    BannerTitle: 'Courses',
    courseZeroStateMsg: 'There is no content assigned to this class.',
    InitialBreadcrumb: 'Course',
    InitialBreadcrumb_plural: 'Courses'
  },
  ClassDetails: {
    BannerTitle: 'Courses'
  },
  ColorKeyCard: {
    courseApproachingLabel: 'Competent',
    courseApproachingSubLabel: '50% - 75%',
    courseDevelopingLabel: 'Beginner',
    courseDevelopingSubLabel: '< 50%',
    courseMeetingLabel: 'Advanced',
    courseMeetingSubLabel: '> 75%',
    standardsApproachingLabel: 'Approaches',
    standardsApproachingSubLabel: '',
    standardsDevelopingLabel: 'Does Not Meet',
    standardsDevelopingSubLabel: '',
    standardsMasteringLabel: 'Masters',
    standardsMasteringSubLabel: '',
    standardsMeetingLabel: 'Meets',
    standardsMeetingSubLabel: ''
  },
  ContentAssignmentModal: {
    allAssignments: 'Related Assignments',
    gradebookLabel: 'Scorecard',
    readyToGrade: 'Ready to Score: '
  },
  ContentElementModal: {
    allAssignments: 'Related Assignments',
    gradebookLabel: 'Scorecard'
  },
  CopyLibraryResourceModal: {
    resourceDescriptionTextSuffix: 'to Assessment Folder.',
  },
  CourseElementModal: {
    allAssignments: 'Related Assignments',
    gradebookLabel: 'Scorecard',
    zeroStateNoItems: 'There are no assessments in this subfolder'
  },
  CourseLayout: {
    allAssignments: 'Related Assignments',
    gradebookLabel: 'Scorecard'
  },
  CourseTreeBranch: {
    editUnitLabel: 'Edit Subfolder'
  },
  CourseTreeLeaf: {
    Grade: 'Score:',
    ViewPreview: 'Open',
    removeFromBank: 'Remove from Folder',
    removeFromBankMessageSuffix: 'from the current assessment folder.  Would you like to complete this action?',
    waitingOnGrade: 'Waiting on Score'
  },
  CreateAssessmentBankUnitModal: {
    createNewUnitHeader: 'Create New Subfolder',
    unitNameLabelSubText: 'Enter a title for the subfolder.',
  },
  CreateNewLibraryResourceModal: {
    assessmentBankLabel: 'Assessment Folder',
    defaultUnitPlaceholderText: 'Subfolder',
    unitNameLabelSubText: 'Assessments are organized into subfolders in an Assessment Folder. You may add additional subfolders later.',
    unitNameLabelText: 'Name First Subfolder',
  },
  DocReaderModal: {
    gradebookLabel: 'Scorecard'
  },
  EditAssignmentModal: {
    closed: 'Closed',
    completed: 'Scored',
    completedDescription: 'Assignments have been manually graded or auto-scored.',
    locked: 'Assigned/Pending',
    ready: 'Assigned/Active',
    readyDescription: 'Assignments are visible and students can start work.',
    reportsAndGrading: 'Reports & Scores',
    scoresReleased: 'Release scores to students',
    started: 'In Progress',
    viewActivityNumberGradebook: 'View Assignment ({{activityNumber}}) Scorecard'
  },
  EditBulkAssignmentModal: {
    closed: 'Closed',
    completed: 'Scored',
    completedDescription: 'Assignments have been manually graded or auto-scored.',
    locked: 'Assigned/Pending',
    ready: 'Assigned/Active',
    readyDescription: 'Assignments are visible and students can start work.',
    reportsAndGrading: 'Reports & Scores',
    scoresReleased: 'Release scores to students',
    started: 'In Progress'
  },
  FileViewerModal: {
    gradebookLabel: 'Scorecard'
  },
  Gradebook: {
    aggregateGradebook: 'Related Assignments',
    allAssignments: 'Related Assignments',
    gradebookLabel: 'Scorecard'
  },
  GradebookCard: {
    scoresReleased: 'Release scores to students'
  },
  GradebookDataBar: {
    graded: 'Scored:'
  },
  GradebookDetailsTable: {
    activityNumber: 'Assignment',
    excludeFromScore_description: 'Exclude is only available before the assignment status is set to "Scored". Bring status back to “Closed” to allow items to be excluded.',
    excludeFromScore_descriptionForAggregate: 'Exclude is only available before the assignment status is set to “Scored”. Bring status back to “Closed” to allow items to be excluded.',
    grade: 'Score',
    graded: 'Scored',
    locked: 'Assigned/Pending',
    ready: 'Assigned/Active',
    slideLabel: 'Screen'
  },
  GradebookHeader: {
    aggregateGradebook: 'Related Assignments'
  },
  GradebookTable: {
    activityNumber: 'Assignment',
    closed: 'Closed',
    completed: 'Scored',
    confirmContent_assignmentStatusUpdatedToClosed: 'This assignment status has been brought from a “Scored” status back to a “Closed” status to allow this item to be excluded or included. When finished the assignment will move automatically back to "Scored".',
    confirmContent_assignmentStatusUpdatedToClosedForAggregate: 'This assignment status has been brought from a “Scored” status back to a “Closed” status to allow this item to be excluded or included. When finished the assignment will move automatically back to "Scored".',
    grade: 'Score',
    includeInReports: 'Scores to Reports',
    locked: 'Assigned/Pending',
    ready: 'Assigned/Active',
    scoresReleased: 'Scores to Students',
    started: 'In Progress',
    studentVersion: 'Student Work'
  },
  LearnosityPrintPreview: {
    gradebookLabel: 'Scorecard'
  },
  LearnosityScoringModal: {
    gradebookLabel: 'Scorecard'
  },
  LibraryAssessmentBankViewModal: {
    afterCopyMsg: 'Assessments copied from Live appear in My Drafts. To edit the copy select Go to My Drafts, select Continue to continue to work within the Assessment Folder. ',
    editAssessmentUnavailableMsg: 'Editing this is not allowed because the assessment is used in a live assessment folder.  Create a copy of this assessment to make changes.',
    editUnavailableTitle: 'Edit Unavailable',
    liveEditingBannerMsg: 'You are live editing an Assessment Folder. Teachers using this Assessment Folder will see your changes immediately.',
    noAssessmentsMsg: 'There are no assessments in this subfolder.',
    unitLabel: 'subfolder',
    zeroStateMsg: 'There is no content for this Assessment Folder.'
  },
  LibraryAssessmentShopModal: {
    removeFromBankMessageSuffix: 'from this Assessment Folder.  Would you like to complete this action?',
    removeFromBankOptionLabel: 'Remove from Assessment Folder',
  },
  LibraryManager: {
    assessmentBankCardLabel: 'Assessment Folder',
    courseCardLabel: 'Course',
    resourceBankCardLabel: 'Pre-Built Assessments'
  },
  LibraryResourceCard: {
    isLiveLabelAssessment: 'Used in Live Assessment Folder',
  },
  MainView: {
    readOnlyGradebookCautionBannerMsg: 'As an Administrator, the scorecard is read only. To make changes, consult with the teacher or switch roles if you also have teacher permissions.'
  },
  NavigationService: {
    InitialBreadcrumb: 'Course',
    InitialBreadcrumb_plural: 'Courses',
    aggregateGradebook: 'Related Assignments',
    gradebookLabel: 'Scorecard'
  },
  ProgressView: {
    BannerTitle: 'Reports',
    CourseProgressLabel: 'Course Reports',
    SummaryLabel: 'Summary (Overall)',
    TrophiesLabel: 'Trophies Earned',
    WeeklyPerformanceLabel: 'Weekly Performance'
  },
  RegisterStudentModal: {
    emailLabel: 'Student Username or email',
    userExistsError: 'There is already a username or email address in our system for this student. Please adjust the username or email address if your student does not yet have access. If your student already has access, they can join a second or third class by clicking “+Join a Class” in their class pulldown in the upper left and then type in the new class access code.'
  },
  ReportContentWithoutScoresToggler: {
    hideContentWithoutScoresFalseLabel: '‏‏‏‏‎ ‎‏‏‎ Content without scores',
    hideContentWithoutScoresTrueLabel: '‏‏‏‏‎ ‎‏‏‎ Content without scores'
  },
  ReportScoreSummaryBarCell: {
    courseApproaching: 'Competent',
    courseDeveloping: 'Beginner',
    courseMastering: 'Advanced',
    courseMeeting: 'Advanced',
    courseNotEnoughData: 'Not Enough Data',
    standardsApproaching: 'Approaches',
    standardsDeveloping: 'Does Not Meet',
    standardsMastering: 'Masters',
    standardsMeeting: 'Meets',
    standardsNotEnoughData: 'Not Enough Data',
  },
  ReportTypeSelectorView: {
    active: 'Active Classes',
    archived: 'Archived Classes',
    viewReportSampleUrl: 'https://publisher.content2classroom.com/api/publicRedirectToResource?contentItemId=95D3029F2A404F03BFF32F5BBBE08BF4'
  },
  ReportView: {
    allAssignments: 'Related Assignments'
  },
  ResourceFilter: {
    filterStandardsAndTagsDescription: 'Select options below to filter by Standards and Tags.',
    filterStandardsAndTagsDescriptionShopping: 'Search for and select existing Assessments to add to your folder. Then select the <strong>Add</strong> button.',
    resourceBankLabel: 'Pre-Built Assessments'
  },
  ResourcePacingModal: {
    reportsAndGrading: 'Reports & Scores',
    scoresReleased: 'Release scores to students'
  },
  ResourcePacingToolbar: {
    manualScoringPrefix: 'Manual Score:',
    manualScoringSuffix: 'Contains items / content that teacher must score.'
  },
  ResourceShopModal: {
    headerTitle: 'Select Assessments',
    liveEditingBannerMsg: 'You are live editing an Assessment Folder. Teachers using this Assessment Folder will see your changes immediately.',
  },
  ResourcesView: {
    title: 'Support'
  },
  ScoreAssignmentModal: {
    grade: 'Scorecard:',
    serverErrorMsg: 'Something went wrong when saving the score, please try again'
  },
  SelectNewLibraryResourceModal: {
    assessmentBankLabel: 'Assessment Folder',
    descriptionText: 'Choose Assessment to build a new test. Choose Assessment Folder to gather existing tests that you want to share with teachers.',
  },
  SelectNewResourceModal: {
    addExistingOptionLabel: 'Add Existing Assessment',
    createNewOptionLabel: 'Create New Assessment',
    descriptionText: 'Create a new assessment or add existing ones.',
  },
  StudentAssignmentCard: {
    grade: 'Score:',
    waitingOnGrade: 'Waiting on Score'
  },
  StudentAssignmentView: {
    NoGradedAssignments: 'No scored assignments'
  },
  StudentContentCardManager: {
    grade: 'Score',
    waitingOnGrade: 'Waiting on Score'
  },
  StudentDashboard: {
    AssignmentLabel: 'Assignments',
    BookLabel: 'Courses'
  },
  StudentTopNav: {
    AssignmentLabel: 'Assignments',
    BookLabel: 'Courses',
    DashboardLabel: 'Dashboard',
    ProgressLabel: 'Reports'
  },
  TeacherDashboard: {
    gradebookLabel: 'Scorecard'
  },
  TopNav: {
    libraryContentLabel: 'Content'
  },
  ZeroStateCard: {
    noReportDataToDisplay: 'No data to display.<br><br>Check back later to view your report. Thereafter, teacher reports refresh twice weekly and are available on Thursday and Saturday mornings. Note that teacher assignment scorecard reports are available in real time, including performance scores, item analysis, engagement, and standards raw data.',
    noReportLastModified: 'Check back later to view your report. Thereafter, teacher reports refresh twice weekly and are available on Thursday and Saturday mornings. Note that teacher assignment scorecard reports are available in real time, including performance scores, item analysis, engagement, and standards raw data.',
    noReportLastModifiedClassroom: 'Check back later to view your report. Thereafter, teacher reports refresh twice weekly and are available on Thursday and Saturday mornings. Note that teacher assignment scorecard reports are available in real time, including performance scores, item analysis, engagement, and standards raw data.',
    selectCourse: 'Select a course to display data.<br><br>Check back later to view your report. Thereafter, teacher reports refresh twice weekly and are available on Thursday and Saturday mornings. Note that teacher assignment scorecard reports are available in real time, including performance scores, item analysis, engagement, and standards raw data.',
    selectStandard: 'Please make pulldown selections above.<br><br>Check back later to view your report. Thereafter, teacher reports refresh twice weekly and are available on Thursday and Saturday mornings. Note that teacher assignment scorecard reports are available in real time, including performance scores, item analysis, engagement, and standards raw data.'
  }
};

export default translations;
