import React from 'react';
import { SatCoreRegister, StudentAssignmentCard } from 'sat-core';

const StudentAssignmentCardOverride = (props) => (
  <StudentAssignmentCard
    {...props}
    dashboard={false} // override display of course title in card
    hideCourseTitle={true} />
);

SatCoreRegister('StudentAssignmentCard', StudentAssignmentCardOverride);
