import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import '../css/WeeklyPerformanceChart.less';

function WeeklyPerformanceChart({ width, data }) {
  const containerRef = useRef();
  const chartRef = useRef();
  const svgRef = useRef();

  let tooltip;

  const verticalSpacing = 44;
  const verticalOffset = 10;
  const height = data.length * verticalSpacing;

  // // this draws a border around the svg to help with debugging
  // useEffect(() => {
  //   const svg = d3.select(svgRef.current) // eslint-disable-line no-unused-vars
  //     .attr('width', width)
  //     .attr('height', height)
  //     .style('border', '1px solid black')
  // }, [width, height]);

  // format some data and build the tooltip html for each item
  useEffect(() => {
    data.forEach((x) => {
      x.value = +x.averageScore.toFixed();
      x.averagePercent = `${x.averageScore.toFixed()}%`;
      x.barLabel = `${x.averagePercent} (${x.submittedCount})`;
      x.tooltip = `
        <div>
          <div class="row">
            <div class="label">Average Score:</div>
            <div>${x.averagePercent}</div>
          </div>
          <div class="row">
            <div class="label">Submitted: </div>
            <div>${x.submittedCount}</div>
          </div>
        </div>`;
    });

    // draw the chart
    draw();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const draw = () => {
    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height);

    const g = svg.selectAll('rect')
      .data(data)
      .enter()
      .append('g');

    const xScale = d3.scaleLinear()
      .domain([0, 100])
      .range([0, width - 115]);

    const getColor = (value) => {
      let color;
      if (value > 75) {
        color = '#009824';
      } else if (value >= 50) {
        color = '#1b8af4';
      } else {
        color = '#d69f00';
      }
      return color;
    };

    const x = d3.scalePoint()
      .domain([...'ABCDE'])
      .range([100, width - 15]);

    // gridlines in x axis function
    const makeGridlines = () => d3.axisBottom(x)
      .tickValues([...'ABCDE']);

    // draw gridlines
    svg.append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0,${height})`)
      .call(makeGridlines()
        .tickSize(-height)
        .tickFormat('')
      );

    g.transition().duration(300)
      .attr('width', (d) => xScale(d.value))
      .attr('x', (d) => 100);

    // draw the bars
    g.append('rect')
      .attr('y', (d, i) => i * verticalSpacing + verticalOffset)
      .attr('x', (d) => 10)
      .attr('width', 0)
      .attr('height', 20)
      .attr('fill', (d) => getColor(d.value))
      .on('mouseenter', (event, d) => {
        const chartRect = chartRef.current.getBoundingClientRect();
        const svgRect = svgRef.current.getBoundingClientRect();
        const scrollAdjust = svgRect.top - chartRect.top;

        tooltip.html(d.tooltip)
          .style('opacity', 1)
          .style('left', `${event.offsetX + 3}px`)
          .style('top', `${event.offsetY + scrollAdjust + 3}px`);
      })
      .on('mouseleave', (d) => {
        tooltip.html('<div></div>')
          .style('opacity', 0)
          .style('left', '0px')
          .style('top', '0px');
      })
      .transition()
      .duration(300)
      .attr('width', (d) => xScale(d.value))
      .attr('x', (d) => 100);

    // add the labels on the bars
    g.append('text')
      .attr('y', (d, i) => i * verticalSpacing + verticalOffset)
      .attr('x', (d) => d.value - 10)
      .attr('width', 0)
      .attr('height', 20)
      .attr('dy', '1.5em')
      .attr('text-anchor', 'left')
      .text((d) => ((d.value > 20) ? d.barLabel : null))
      .style('font-size', '8px')
      .style('font-weight', 'bold')
      .style('fill', '#ffffff')
      .transition()
      .duration(300)
      .attr('x', (d) => xScale(d.value) + 85 - (d.barLabel.length * 4));

    // add the first line of the week range on the y-axis
    g.append('text')
      .attr('y', (d, i) => i * verticalSpacing + verticalOffset - 10)
      .attr('x', (d) => d.value - 10)
      .attr('width', 0)
      .attr('height', 20)
      .attr('dy', '1.5em')
      .attr('text-anchor', 'left')
      .text((d) => `${d.start} -`)
      .style('font-size', '9px')
      .style('font-weight', '600')
      .style('fill', '#000000')
      .transition()
      .duration(300)
      .attr('x', (d) => 10);

    // add the second line of the week range on the y-axis
    g.append('text')
      .attr('y', (d, i) => i * verticalSpacing + verticalOffset + 10)
      .attr('x', (d) => d.value - 10)
      .attr('width', 0)
      .attr('height', 20)
      .attr('dy', '1.0em')
      .attr('text-anchor', 'left')
      .text((d) => d.end)
      .style('font-size', '9px')
      .style('font-weight', 'bold')
      .style('fill', '#000000')
      .transition()
      .duration(300)
      .attr('x', (d) => 10);

    // create the tooltip div and hide it
    tooltip = d3.select(containerRef.current)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'weekly-performance-tooltip');

    g.exit()
      .transition().duration(300)
      .attr('x', (d) => 100)
      .attr('width', width)
      .remove();
  };

  const chartClass = (data.length < 5) ? 'chart' : 'scrollingChart';

  return (
    <div ref={containerRef} className='weekly-performance-chart'>
      <div ref={chartRef} className={chartClass}>
        <svg ref={svgRef} />
      </div>
      <div className='x-axis'>
        <div className='tick0'>0%</div>
        <div className='tick1'>25%</div>
        <div className='tick2'>50%</div>
        <div className='tick3'>75%</div>
        <div className='tick4'>100%</div>
      </div>
    </div>
  );
}

export default WeeklyPerformanceChart;
