import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AssignmentCard, SatCoreRegister } from 'sat-core';

@inject(
  'classroomManager', 'courseManager', 'dialogManager', 'userManager'
)
@observer
class AssignmentCardOverride extends Component {
  render() {
    const {
      key,
      assignment,
      fetchCourseActivityInformation,
      handlePresent,
      handleEditAssignment,
      handleGradebook,
      contentImageUrl,
      handleAnswerKey,
      t
    } = this.props;

    return (
      <>
        <AssignmentCard
          key={key}
          assignment={assignment}
          contentImageUrl={contentImageUrl}
          fetchCourseActivityInformation={fetchCourseActivityInformation}
          handleAnswerKey={handleAnswerKey}
          handleEditAssignment={handleEditAssignment}
          handleGradebook={handleGradebook}
          handlePresent={handlePresent}
          hideCourseTitle={true} // override display of course title in card
          showExpirationAlerts={true} // override to show alert icon in title area for expir(ed/ing) assignments.
          t={t} />
      </>
    );
  }
}

SatCoreRegister('AssignmentCard', AssignmentCardOverride);
