/* eslint-disable sort-keys */
import { ReportScoreService } from 'sat-core';
import { SIRIUS_DEFAULT_RANGE_KEY, SIRIUS_REPORT_RANGE_KEY } from './SiriusReportRangeKeyObj';

ReportScoreService.COURSE_CUT_SCORE = {
  MASTERING: (_score) => {
    // currently unused
    return false;
  },
  /** **'Advanced'** */
  MEETING: (score) => {
    return score > 75;
  },
  /** **'Competent'** */
  APPROACHING: (score) => {
    return score <= 75 && score >= 50;
  },
  /** **'Beginner'** */
  DEVELOPING: (score) => {
    return score < 50 && score >= 0;
  },
  NOT_ENOUGH_DATA: (score) => {
    return typeof score !== 'number';
  }
};

ReportScoreService.STANDARDS_CUT_SCORE = {
  /** **'Masters'** */
  MASTERING: (score, rangeKeyName) => {
    const rangeKey = rangeKeyName ? SIRIUS_REPORT_RANGE_KEY[rangeKeyName] : SIRIUS_DEFAULT_RANGE_KEY;
    const { mastering } = rangeKey;
    return score >= mastering;
  },
  /** **'Meets'** */
  MEETING: (score, rangeKeyName) => {
    const rangeKey = rangeKeyName ? SIRIUS_REPORT_RANGE_KEY[rangeKeyName] : SIRIUS_DEFAULT_RANGE_KEY;
    const { mastering, meeting } = rangeKey;
    return score < mastering && score >= meeting;
  },
  /** **'Approaches'** */
  APPROACHING: (score, rangeKeyName) => {
    const rangeKey = rangeKeyName ? SIRIUS_REPORT_RANGE_KEY[rangeKeyName] : SIRIUS_DEFAULT_RANGE_KEY;
    const { meeting, approaching } = rangeKey;
    return score < meeting && score >= approaching;
  },
  /** **'Does Not Meet'** */
  DEVELOPING: (score, rangeKeyName) => {
    const rangeKey = rangeKeyName ? SIRIUS_REPORT_RANGE_KEY[rangeKeyName] : SIRIUS_DEFAULT_RANGE_KEY;
    const { approaching } = rangeKey;
    return score < approaching;
  },
  NOT_ENOUGH_DATA: (score, _rangeKeyName) => {
    return typeof score !== 'number';
  }
};

ReportScoreService.ALLOWED_CUT_SCORE = () => {
  return {
    COURSE: {
      MASTERING: false,
      MEETING: true,
      APPROACHING: true,
      DEVELOPING: true,
      NOT_ENOUGH_DATA: true
    },
    STANDARDS: {
      MASTERING: true,
      MEETING: true,
      APPROACHING: true,
      DEVELOPING: true,
      NOT_ENOUGH_DATA: true
    }
  };
};

ReportScoreService.getScoreCellProgressClassName = (cell, rangeKeyName, progressFilters) => {
  const score = cell?.value?.averageScore;
  const progressScore = cell?.value?.progressScore;

  let className = 'no-data';
  if (typeof score === 'number' && typeof progressScore === 'number') {
    const rangeKey = rangeKeyName ? SIRIUS_REPORT_RANGE_KEY[rangeKeyName] : SIRIUS_DEFAULT_RANGE_KEY;
    const { meeting, approaching } = rangeKey;
    const meetingThreshold = (100 - meeting) / 2;
    const approachingThreshold = (meeting - approaching) / 2;
    const developingThreshold = approaching / 2;

    if (score >= meeting) {
      // mastering or meeting
      if (progressScore > meetingThreshold && progressFilters.highMore) {
        className = 'high-more';
      } else if (progressScore <= meetingThreshold && progressFilters.highLess) {
        className = 'high-less';
      }
    } else if (score >= approaching) {
      // approaching
      if (progressScore > approachingThreshold && progressFilters.averageMore) {
        className = 'average-more';
      } else if (progressScore <= approachingThreshold && progressFilters.averageLess) {
        className = 'average-less';
      }
    } else {
      // devoloping
      if (progressScore > developingThreshold && progressFilters.lowMore) {
        className = 'low-more';
      } else if (progressScore <= developingThreshold && progressFilters.lowLess) {
        className = 'low-less';
      }
    }
  }

  return className;
};
