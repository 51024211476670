import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import {
  ASSIGNMENT_DATA_EXPORT_TYPE, Auth, FLOWPAPER_SESSION_KEYS, accommodationsManager,
  assignmentManager, classroomManager, contentManager, courseDeliveryManager,
  courseManager, dialogManager, exportManager, gradebookManager, i18nAddSatellite,
  navigationManager, reportOverrideManager, reportStandardsManager, userManager
} from 'sat-core';
import * as serviceWorker from './serviceWorker';

import { API_ROOT, LESSON_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE } from './api-config';

import icon_texas from './img/states/icon-texas.svg';

import logo from './img/sirius-logo-toolbar.png';
import 'semantic-ui-less/semantic.less';
import './index.less';

/* Put override imports here */
import App from './views/AppOverride';
import './components/AssignmentCardOverride';
import './components/CourseListBannerOverride';
import './components/FeedbackIconOverride';
import './components/StudentAssignmentCardOverride';
import './services/AccommodationsServiceOverride';
import './services/SiriusReportRangeKeyObj';
import './services/SiriusReportScoreServiceOverride';
import './views/LoginViewOverride';
import './views/MainViewOverride';
import './views/ProgressViewOverride';
import './views/ReportTypeSelectorViewOverride';

import siriusTranslations from './siriusTranslations';

i18nAddSatellite('sirius', siriusTranslations);

Auth.init(API_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE, logo, LESSON_ROOT);

ASSIGNMENT_DATA_EXPORT_TYPE.ITEM_ANALYSIS = 'EXPORT_SIR_ACT_ITEM_ANALYSIS';

// TODO customize these for Sirius in R57 and uncomment line below to set it on the itemBankManager.
const questionTypeOptions =
  [
    { key: 'Categorize', value: 'Categorize', text: 'Classify' },
    { key: 'ClozeDragDrop', value: 'ClozeDragDrop', text: 'Fill In The Blank Drag-Drop' },
    { key: 'ClozeMath', value: 'ClozeMath', text: 'Fill In The Blank Math' },
    { key: 'ClozeMultichoice', value: 'ClozeMultichoice', text: 'Fill In The Blank Multi-Choice' },
    { key: 'Cloze', value: 'Cloze', text: 'Fill In The Blank Typing' },
    { key: 'Free-Draw', value: 'Draw', text: 'Free-Draw' },
    { key: 'LongText', value: 'LongText', text: 'Free Response' },
    { key: 'Griddable', value: 'Griddable', text: 'Griddable' },
    { key: 'TextHighlight', value: 'TextHighlight', text: 'Highlight Text' },
    { key: 'HotText', value: 'HotText', text: 'Hot Text' },
    { key: 'ImageHotSpot', value: 'ImageHotSpot', text: 'Image Hot Spot' },
    { key: 'ImageLabel', value: 'ImageLabel', text: 'Label Image Typing' },
    { key: 'ImageLabelMultichoice', value: 'ImageLabelMultichoice', text: 'Label Image Multiple Choice' },
    { key: 'ImageLabelDragDrop', value: 'ImageLabelDragDrop', text: 'Label Image Drag Drop' },
    { key: 'Multichoice', value: 'Multichoice', text: 'Multiple Choice' },
    { key: 'ChoiceMatrixMulti', value: 'ChoiceMatrixMulti', text: 'Multiple Choice Table' },
    { key: 'Multiselect', value: 'Multiselect', text: 'Multiple Select' },
    { key: 'NumberLine', value: 'NumberLine', text: 'Number Line' },
    { key: 'OrderList', value: 'OrderList', text: 'Order List' },
    { key: 'OrderSentences', value: 'OrderSentences', text: 'Order Sentences' },
    { key: 'TextSelector', value: 'TextSelector', text: 'Select Text' },
    { key: 'ShortText', value: 'ShortText', text: 'Short Response' },
    { key: 'ChoiceMatrix', value: 'ChoiceMatrix', text: 'True - False' },
    { key: 'Upload', value: 'Upload', text: 'Upload Files' },
    { key: 'MultipartActivity', value: 'MultipartActivity', text: 'Multipart Activity' },
    { key: 'VideoQuestion', value: 'VideoQuestion', text: 'Video Activity' },
    { key: 'AudioQuestion', value: 'AudioQuestion', text: 'Audio Activity' },
    { key: 'BookletActivity', value: 'BookletActivity', text: 'Book Activity' },
    { key: 'BookmarkActivity', value: 'BookmarkActivity', text: 'Bookmark Activity' },
    { key: 'ArticleQuestion', value: 'ArticleQuestion', text: 'Passage Activity' },
    { key: 'ImageQuestion', value: 'ImageQuestion', text: 'Image Activity' },
    { key: 'StudentInstruction', value: 'StudentInstruction', text: 'Student Instructions' },
    { key: 'TeacherInstruction', value: 'TeacherInstruction', text: 'Teacher Instructions' }

    // { key: 'TChart', value: 'TChart', text: 'T Chart'}
    // { key: 'ClozeSpelling', value: 'ClozeSpelling', text: 'Spelling' },
    // { key: 'ImageTextMatch', value: 'ImageTextMatch', text: 'Image Text Match' },
    // { key: 'ImageTextMatchFlip', value: 'ImageTextMatchFlip', text: 'Image Text Flip' }
  ];

// itemBankManager.setQuestionTypeOptions(questionTypeOptions); // Uncomment after questionTypeOptions are updated for Sirius
accommodationsManager.setIncludeClassroomAccommodations(true);
assignmentManager.setEnableCourseSearch(true);
assignmentManager.setIncludeAlignments(true);
assignmentManager.setUseAssignmentNickname(false);
assignmentManager.setUseExpandedAssignmentCard(false);
classroomManager.setAllowSsoAddExistingStudentToSelfRegClassroom(true);
classroomManager.setAllowSsoCreateSelfRegClassroom(true);
classroomManager.setAllowSsoViewClassroomSettings(true);
classroomManager.setAllowStudentClassMenuAltPosition(true);
classroomManager.setEnabledStudentsOnly(false);
contentManager.setFlowpaperSessionKeyTeacherPreview(FLOWPAPER_SESSION_KEYS.CLASSROOM_CRE_USER);
contentManager.setResourceIFrameUrl('https://sirius4learning.zohodesk.com/portal/en/home');
contentManager.setUserFlowpaperAnswerKey(true);
courseDeliveryManager.setAllowedResourcePillKeys(['manualScored']);
courseDeliveryManager.setResourcePacingToolbarItemKeysToAutoToggleWithoutOpeningModal(['hiddenFromStudents', 'visibleToStudents']);
courseDeliveryManager.setResourcePacingToolbarItemKeysToExclude(['attempts', 'studentPaced', 'teacherAssign']);
courseManager.setIncludeAlignments(true);
dialogManager.setUseWhiteInfoIcon(true);
exportManager.setAllowExportFullCourseSchoolDetailByStudentReport(true);
exportManager.setAllowExportFullStandardsSchoolDetailByStudentReport(true);
gradebookManager.setAllowAssignmentLink(true);
gradebookManager.setAllowGradebookStandards(true);
gradebookManager.setUseStudentWorkScoreButton(true);
reportOverrideManager.setAllowLastModifiedAdditionalReportInfo(true);
reportOverrideManager.setAllowProgressReportContext(true);
reportOverrideManager.setAllowReportTableRowResourceExport(true);
reportOverrideManager.setAllowUseCmapId(true);
reportOverrideManager.setEnableEnhancedUsageReports(true);
reportOverrideManager.setReportTypeSelectorViewIconOverride({ standardsIcon: icon_texas });
reportOverrideManager.setShouldShowStandardsReportSummaryBar(true);
reportStandardsManager.setShowCutScoreAssignType(true);
userManager.setAllowViewSatelliteProductTab(false);
userManager.setClientPrefix('SIRIUS');
userManager.setRestrictAvatar(true);
userManager.setShowTextHelpForAll(true);

ReactDOM.render(
  <Provider
    assignmentManager={assignmentManager}
    classroomManager={classroomManager}
    contentManager={contentManager}
    courseManager={courseManager}
    dialogManager={dialogManager}
    gradeBookManager={gradebookManager}
    navigationManager={navigationManager}
    userManager={userManager}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
