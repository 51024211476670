import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { MainView, SatCoreRegister, VIEW_SELECTION } from 'sat-core';

export default
@inject('contentManager', 'userManager')
@observer
class MainViewOverride extends Component {
  showResources = async (props) => {
    const { contentManager } = this.props;
    //  TODO: keep this and view in case iFrame works out later release.
    //  props.navigationManager.setView(VIEW_SELECTION.RESOURCES);
    //  await props.history.push(`/resources?view=${VIEW_SELECTION.RESOURCES}`);

    if (contentManager.resourceIFrameUrl !== null) {
      window.open(contentManager.resourceIFrameUrl, '_blank');
    }
  }

  handleClickTermsOfUse = () => {
    window.open('https://sirius4learning.zohodesk.com/portal/en/kb/articles/sirius-education-solutions-terms-of-use', '_blank');
  }

  handleClickFeedback = () => {
    window.open('https://survey.zohopublic.com/zs/U7B3xO', '_blank');
  }

  handleClickCopyright = () => {
    window.open('https://sirius4learning.zohodesk.com/portal/en/kb/articles/sirius-staar-prep-resources-are-copyright-protecte', '_blank');
  }

  handleClickPrivacyPolicy = () => {
    window.open('https://sirius4learning.zohodesk.com/portal/en/kb/articles/sirius-online-staar-preparation-and-practice-privacy-policy', '_blank');
  }

  getNavTabs = (props, parent) => {
    const { userManager: { isDistrictAdmin, isSchoolAdmin, isProductAdmin, isTeacher, isStudent } } = this.props;
    const help = parent.renderMenuItem(
      'Support',
      VIEW_SELECTION.RESOURCES,
      { className: 'resources', onClick: () => this.showResources(props) }
    );
    if (isDistrictAdmin || isSchoolAdmin || isProductAdmin) {
      return help;
    } else if (isTeacher) {
      return (
        <>
          {parent.getBaseNavTabs()}
          {help}
        </>
      );
    } else if (isStudent) {
      return parent.getBaseNavTabs();
    }
  }

  getProfileMenu = () => {
    const { userManager: { isDistrictAdmin, isSchoolAdmin, isProductAdmin, isTeacher } } = this.props;
    let items = [
      { text: 'Copyright Notice', onClick: this.handleClickCopyright },
      { text: 'Terms of Use', onClick: this.handleClickTermsOfUse },
      { text: 'Privacy Policy', onClick: this.handleClickPrivacyPolicy }
    ];

    if (isDistrictAdmin || isSchoolAdmin || isProductAdmin || isTeacher) {
      items = [{ text: 'Feedback', onClick: this.handleClickFeedback }, ...items];
    }

    return { items };
  }

  render() {
    return <MainView {...this.props} getNavTabs={this.getNavTabs} topNav={{ profileMenu: this.getProfileMenu() }} />;
  }
}

SatCoreRegister('MainView', MainViewOverride);
