import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { SatCoreComponent, dialogManager } from 'sat-core';

export default @inject(
  'dialogManager'
)
@observer
class AppOverride extends Component {
  constructor(props) {
    super(props);
    this.App = SatCoreComponent('App');
  }

  render() {
    const { App } = this;
    return (
      <>
        <App />
      </>
    );
  }
}
