/* eslint-disable sort-keys */
export const SIRIUS_DEFAULT_RANGE_KEY = {
  approaching: 45,
  meeting: 64,
  mastering: 80
};

export const SIRIUS_REPORT_RANGE_KEY = {
  'Reading Grade 3': {
    approaching: 45,
    meeting: 64,
    mastering: 80
  },
  'Reading Grade 4': {
    approaching: 41,
    meeting: 62,
    mastering: 76
  },
  'Reading Grade 5': {
    approaching: 50,
    meeting: 70,
    mastering: 82
  },
  'Reading Grade 6': {
    approaching: 46,
    meeting: 64,
    mastering: 80
  },
  'Reading Grade 7': {
    approaching: 51,
    meeting: 69,
    mastering: 82
  },
  'Reading Grade 8': {
    approaching: 44,
    meeting: 64,
    mastering: 78
  },
  'Reading English I': {
    approaching: 52,
    meeting: 66,
    mastering: 91
  },
  'Reading English II': {
    approaching: 52,
    meeting: 66,
    mastering: 95
  },
  'Math Grade 3': {
    approaching: 48,
    meeting: 67,
    mastering: 83
  },
  'Math Grade 4': {
    approaching: 50,
    meeting: 68,
    mastering: 85
  },
  'Math Grade 5': {
    approaching: 46,
    meeting: 67,
    mastering: 86
  },
  'Math Grade 6': {
    approaching: 45,
    meeting: 66,
    mastering: 84
  },
  'Math Grade 7': {
    approaching: 51,
    meeting: 67,
    mastering: 87
  },
  'Math Grade 8': {
    approaching: 45,
    meeting: 64,
    mastering: 84
  },
  'Math Algebra I': {
    approaching: 44,
    meeting: 64,
    mastering: 76
  },
  'Math Skills Review': {
    approaching: 66,
    meeting: 77,
    mastering: 88
  },
  'Science Grade 5': {
    approaching: 56,
    meeting: 74,
    mastering: 84
  },
  'Science Grade 8': {
    approaching: 47,
    meeting: 64,
    mastering: 83
  },
  'Science Biology': {
    approaching: 36,
    meeting: 57,
    mastering: 79
  },
  'Social Studies Grade 8': {
    approaching: 53,
    meeting: 71,
    mastering: 80
  },
  'Social Studies U.S. History': {
    approaching: 38,
    meeting: 56,
    mastering: 71
  },
  // 'Writing Grade 4': {
  //   approaching: 56,
  //   meeting: 71,
  //   mastering: 83
  // },
  // 'Writing Grade 7': {
  //   approaching: 57,
  //   meeting: 71,
  //   mastering: 84
  // }
};
