import React from 'react';
import { Image } from 'semantic-ui-react';
import { FeedbackIcon, SatCoreRegister } from 'sat-core';
import iconCommentActive from '../img/icon-comment-active.svg';
import iconCommentNone from '../img/icon-comment-none.svg';
// import '../../css/FeedbackIcon.less';

export const FeedbackIconOverride = (props) => {
  const { className, clickHandler, hasFeedback } = props;
  return (
    <Image
      alt=''
      className={`feedback clickable ${className}`}
      onClick={clickHandler}
      src={hasFeedback ? iconCommentActive : iconCommentNone} />
  );
};
export default FeedbackIcon;

SatCoreRegister('FeedbackIcon', FeedbackIconOverride);
