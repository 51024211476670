import React from 'react';

import { Button } from 'semantic-ui-react';

import {
  ReportTypeSelectorView, SatCoreRegister
} from 'sat-core';

const handleShowReportSample = (props) => {
  const { t } = props;
  window.open(t('viewReportSampleUrl'));
};

const renderReportSampleButton = (props) => {
  const { t } = props;
  return (
    <div className='button-report-sample-wrapper'>
      <Button
        className='primary button-report-sample'
        onClick={() => handleShowReportSample(props)}>
        <div className='button-view-report-title'>
          {t('showReportSampleButton')}
        </div>
      </Button>
    </div>
  );
};

const ReportTypeSelectorViewOverride = (props) => {
  return (
    <ReportTypeSelectorView
      {...props}
      renderReportSampleButton={renderReportSampleButton} />
  );
};

SatCoreRegister('ReportTypeSelectorView', ReportTypeSelectorViewOverride);
