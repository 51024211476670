let ecmsBaseUrl;
let publisherBaseUrl;
let lessonBaseUrl;
const publisherSatelliteCode = 'SIRIUS_SATELLITE';

const hostname = window && window.location && window.location.hostname;

if (hostname === 'sirius.content2classroom.com') {
  ecmsBaseUrl = 'https://sirius.content2classroom.com';
  publisherBaseUrl = 'https://publisher.content2classroom.com';
  lessonBaseUrl = 'https://lesson.content2classroom.com';
} else if (hostname === 'staging-sirius.content2classroom.com') {
  ecmsBaseUrl = 'https://staging-sirius.content2classroom.com';
  publisherBaseUrl = 'https://staging-publisher.content2classroom.com';
  lessonBaseUrl = 'https://staging-lesson.content2classroom.com';
} else if (hostname === 'qa-sirius.content2classroom.com') {
  ecmsBaseUrl = 'https://qa-sirius.content2classroom.com';
  publisherBaseUrl = 'https://qa-publisher.content2classroom.com';
  lessonBaseUrl = 'https://qa-lesson.content2classroom.com';
} else if (hostname === 'qaprod-sirius.content2classroom.com') {
  ecmsBaseUrl = 'https://qaprod-sirius.content2classroom.com';
  publisherBaseUrl = 'https://qaprod-publisher.content2classroom.com';
  lessonBaseUrl = 'https://qaprod-lesson.content2classroom.com';
} else if (hostname === 'ast-sirius.content2classroom.com') {
  ecmsBaseUrl = 'https://ast-sirius.content2classroom.com';
  publisherBaseUrl = 'https://ast-publisher.content2classroom.com';
  lessonBaseUrl = 'https://ast-lesson.content2classroom.com';
} else if (hostname === 'dev.content2classroom.com') {
  ecmsBaseUrl = 'https://qa-sirius.content2classroom.com';
  publisherBaseUrl = 'https://qa-publisher.content2classroom.com';
  lessonBaseUrl = 'https://qa-lesson.content2classroom.com';
} else {
  if (process.env.REACT_APP_URI_SCHEME === 'https') {
    ecmsBaseUrl = 'https://localhost:8080';
    publisherBaseUrl = 'https://localhost:7777';
    lessonBaseUrl = 'https://localhost:3001';
  } else {
    ecmsBaseUrl = 'http://localhost:8080';
    publisherBaseUrl = 'http://localhost:7777';
    lessonBaseUrl = 'http://localhost:3001';
  }
}

export const API_ROOT = `${ecmsBaseUrl}`;
export const PUBLISHER_ROOT = `${publisherBaseUrl}`;
export const PUBLISHER_SATELLITE_CODE = `${publisherSatelliteCode}`;
export const LESSON_ROOT = `${lessonBaseUrl}`;
