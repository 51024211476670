/* eslint-disable sort-keys */
import { AccommodationsService } from 'sat-core';

AccommodationsService.ACCOMMODATIONS_CONFIG = () => {
  return {
    assessmentSection: {
      show: true, // overrides all other assessment options if false
      toggles: {
        // toggles marked as true will appear in the order shown here
        basicCalculatorToggle: true,
        textToSpeechToggle: true,
        translationToggle: true,
        dictionaryToggle: false,
        contentLanguageToggle: true,
        scientificCalculatorToggle: false,
        graphingCalculatorToggle: false,
        spellcheckerToggle: true
      }
    },
    lessonSection: {
      show: true, // overrides all other lesson options if false
      toggles: {
        // toggles marked as true will appear in the order shown here
        basicCalculatorToggle: true,
        textToSpeechToggle: true,
        translationToggle: true,
        dictionaryToggle: true,
        scientificCalculatorToggle: false,
        graphingCalculatorToggle: false,
        spellcheckerToggle: true
      }
    },
    cellDividerClassName: 'section-divider',
    showTopHeader: true,
    tableHeaderFontSize: 'inherit',
    treatAllSectionsAsOne: false
  };
};
