import React from 'react';

import { Login, SatCoreRegister } from 'sat-core';

import { Divider } from 'semantic-ui-react';

// const RegistrationMessage = () => (
//   <>
//     <Divider />
//     <div className='signin-message'>
//       If your school is not using Self Registration, please access Sirius Online through your school’s portal.
//     </div>
//   </>
// );

const LoginViewOverride = (props) => <Login {...props} authProviderButtonsEnabled={true} />;

SatCoreRegister('Login', LoginViewOverride);
