import React from 'react';
import { CourseListBanner, SatCoreRegister } from 'sat-core';

import '../css/SiriusCourseStyleOverrides.less';

const CourseListBannerOverride = (props) => {
  const { showBreadCrumbs, showBreadCrumbsDropdown } = props;
  return (
    <CourseListBanner
      {...props}
      showBreadCrumbs={showBreadCrumbs && 'top'}
      showBreadCrumbsDropdown={showBreadCrumbsDropdown && 'top'}
      themeHeaderTitle={<h2>{/* placeholder */}</h2>} />
  );
};

SatCoreRegister('CourseListBanner', CourseListBannerOverride);
